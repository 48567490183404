// External library imports
import * as yup from 'yup';
import moment from 'moment';
// Internal module imports
import { ERRORS } from '../../utils/validationHelper';

const validateNumber = () => yup.number().typeError(ERRORS.number).min(0).required(ERRORS.required);
const capacityAndPriceValidation = () =>
    yup
        .array()
        .of(validateNumber())
        .min(['FCR'].includes(yup.ref('biddingMarket')) ? 6 : ['FFR', 'FCR-D-INC'].includes(yup.ref('biddingMarket')) ? 24 : 1);

export const RequestBidValidation = yup.object().shape({
    capacity: capacityAndPriceValidation(),
    price: capacityAndPriceValidation(),
    groupId: yup.string().required(ERRORS.required),
    startDate: yup.date().required(ERRORS.required),
    endDate: yup
        .date()
        .required(ERRORS.required)
        .test('is-after-or-equal', "End date can't be before start date", (value, c) => {
            const startDate = c.resolve(yup.ref('startDate'));
            if (!value || !startDate) return false;
            const formattedValue = moment(value).startOf('day');
            const formattedStartDate = moment(startDate).startOf('day');
            return formattedValue.isSameOrAfter(formattedStartDate);
        }),
    excludedDays: yup
        .array()
        .of(
            yup.object({
                date: yup.string().required(ERRORS.required),
                reason: yup.string().required(ERRORS.required),
            })
        )
        .min(0),
    note: yup.string().required(ERRORS.required),
});

export const MaintenanceHourValidationValidation = yup.object().shape({
    groupId: yup.string().required(ERRORS.required),
    date: yup.date().required(ERRORS.required),
    hours: yup
        .array()
        .of(yup.object().shape({ label: yup.string(), value: yup.number() }))
        .required()
        .min(1),
    note: yup.string().required(ERRORS.required),
});
