import React, { useEffect, useState } from 'react';
import ModalComponent from '../../ModalComponent/ModalComponent';
import classes from '../DatePicker/DatePicker.module.css';
import { UilSchedule } from '@iconscout/react-unicons';
import moment from 'moment';
import monthPickerClasses from './MonthPicker.module.css';
import { MONTHS } from '../../../constants';
function MonthPicker({ date, setDate, disabled, showArrow = true, style = {}, containerStyle = {}, ...props }) {
    const [showCalender, setShowCalender] = useState(false);
    const [year, setYear] = useState(moment().format('yyyy'));
    const selectedDate = date && moment(date).format('MMM');

    const handleBackClick = () => {
        const previousMonth = new Date(date);
        previousMonth.setMonth(previousMonth.getMonth() - 1);
        setDate(previousMonth);
    };

    const handleNextClick = () => {
        const nextMonth = new Date(date);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        setDate(nextMonth);
    };

    const changeYear = (type) => {
        type == 'next' ? setYear(moment(year).add(1, 'years').format('yyyy')) : setYear(moment(year).subtract(1, 'years').format('yyyy'));
    };

    const handleMonth = (e) => {
        const date = `${year}-${e}-01`;
        setDate(new Date(date));
        setShowCalender(false);
    };

    return (
        <div>
            <div>
                <div className={classes.DatePickerContainer} style={disabled ? { opacity: '0.5' } : style}>
                    {showArrow && (
                        <div className={classes.ArrowButton} onClick={() => !disabled && handleBackClick()}>
                            {'<'}
                        </div>
                    )}
                    <div className={classes.DateContainer} style={containerStyle} onClick={() => !disabled && setShowCalender(!showCalender)}>
                        <div className={classes.Date}>{date && moment(date).format('MMM YY')}</div>
                        <div className={classes.CalendarIcon}>
                            <UilSchedule style={{ color: 'var(--color-primary)' }} size={'1.042vw'} />
                        </div>
                    </div>
                    {showArrow && (
                        <div className={classes.ArrowButton} onClick={() => !disabled && handleNextClick()}>
                            {'>'}
                        </div>
                    )}
                </div>

                {showCalender && (
                    <ModalComponent isOpen={showCalender} setOpen={setShowCalender}>
                        <div style={{ textAlign: 'center' }}>
                            <div className={monthPickerClasses.Year}>
                                <div className={classes.ArrowButton} onClick={() => changeYear('prev')}>
                                    {'<'}
                                </div>
                                {year}
                                <div className={classes.ArrowButton} onClick={() => changeYear('next')}>
                                    {'>'}
                                </div>
                            </div>

                            <div>
                                <div className={monthPickerClasses.Month}>
                                    {MONTHS.map((item, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                width: '33.33%',
                                                boxSizing: 'border-box',
                                            }}
                                            onClick={() => handleMonth(item.value)}
                                            className={selectedDate == item.label ? monthPickerClasses.Selected : ''}
                                        >
                                            <label
                                                style={{ fontSize: '12px', color: 'var(--color-primary)' }}
                                                className={selectedDate == item.label ? monthPickerClasses.label : ''}
                                            >
                                                {' '}
                                                {item.label}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </ModalComponent>
                )}
            </div>
        </div>
    );
}

export default MonthPicker;
