import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import Typography from '../../../components/Typography/Typography';
import { useLoader } from '../../../hooks/index';
import { NordpoolService } from '../../../services/NordpoolService';
import classes from '../Nordpool.module.css';
import Button from '../../../components/Buttons/Button';
import DownloadIcon from '../../../assets/download.svg';
import { DropdownComponent } from '../../../components/Inputs/Input';
import MonthPicker from '../../../components/Inputs/MonthPicker/MonthPicker';

function DownloadFilterModal({ open, setOpen, PriceDropdown }) {
    const [startLoader, stopLoader] = useLoader();

    let lastDay = moment().endOf('M');
    const [dateRange, setDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(lastDay),
    });
    const [priceSignal, setPriceSignal] = useState('');
    useEffect(() => {
        PriceDropdown && setPriceSignal(PriceDropdown[0]);
    }, [PriceDropdown]);

    const fetchData = () => {
        if (priceSignal?.value) {
            let processedDate = {
                startDate: moment(dateRange.startDate).startOf('M').format('YYYY-MM-DD'),
                endDate: moment(dateRange.startDate).endOf('M').format('YYYY-MM-DD'),
                priceSignal: priceSignal?.label,
                priceSignalId: priceSignal?.value,
            };
            const loaderKey = 'excelPrice';
            NordpoolService.getPowerCostExcelData(
                processedDate,
                () => startLoader(loaderKey),
                handleExportDataSuccess,
                handleError,
                () => stopLoader(loaderKey)
            );
        }
    };

    const handleExportDataSuccess = ({ data }) => {
        DownloadAsExcel(
            data?.data,
            `${priceSignal?.label} ${moment(dateRange.startDate).startOf('M').format('YYYY-MM-DD')}-${moment(dateRange.startDate).endOf('M').format('YYYY-MM-DD')}`,
            ['Date', 'Price']
        );
    };

    const handleError = (error) => {
        console.log(error);
    };

    const minStartDate = new Date();
    minStartDate.setDate(dateRange.endDate.getDate() - 29);

    const maxEndDate = new Date();
    maxEndDate.setDate(dateRange.startDate.getDate());
    return (
        <ModalComponent isOpen={open} setOpen={setOpen}>
            <div style={{ width: '20vw' }}>
                <Typography content={'Download Price (Tax Included)'} />
                <div className={classes.InputContainer}>
                    <div className={classes.FieldControl2}>
                        <label>Price Signal</label>
                        <div>
                            <DropdownComponent
                                defaultValue={priceSignal}
                                options={PriceDropdown}
                                onChange={(value) => {
                                    setPriceSignal(value);
                                }}
                            />
                        </div>
                    </div>
                    <div className={classes.FieldControl2}>
                        <label>Month</label>
                        <MonthPicker
                            showArrow={false}
                            style={{
                                display: 'block',
                            }}
                            containerStyle={{
                                width: 'auto',
                                height: '2.5vw',
                            }}
                            date={dateRange.startDate}
                            setDate={(e) => setDateRange({ ...dateRange, startDate: e })}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.ButtonContainer}>
                <div>
                    <div className="btn-secondary" onClick={() => setOpen(false)}>
                        Cancel
                    </div>
                </div>
                <div
                    onClick={() => {
                        fetchData();
                    }}
                >
                    <button type="submit" className="btn-primary">
                        Submit
                    </button>
                </div>
            </div>
        </ModalComponent>
    );
}

export default DownloadFilterModal;
